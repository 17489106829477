import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetector from "i18next-browser-languagedetector";

// the translations
import translationEn from "./translate/en.json";
import translationFr from "./translate/fr.json";

const resources = {
  en: {
    translation: translationEn
  },
  fr: {
    translation: translationFr
  }
};

// Get language from local storage or default to 'fr' (French)
const storedLanguage = localStorage.getItem("language") || "fr";

i18n
  .use(languageDetector)
  .use(initReactI18next) 
  .init({
    resources,
    lng: storedLanguage, 
    interpolation: {
      escapeValue: false 
    },
    react: { useSuspense: false }
  });

// Function to change language
export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng); // Change language
  localStorage.setItem("language", lng); // Store language in local storage
};

export default i18n;
