import React from "react";
import "./../assets/css/mentionslegales.css";
import Footer from "../layouts/footer/footer";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import { useTranslation } from "react-i18next";
import ProgressBar from "./ProgressBar ";

const Mentionslegales = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <br />
      <br />
      <br />
      <div className="container-fluid" id="mentionslegales">
        <p>
          <strong>{t("mentionslegales.presentation.titre")}</strong> <br />
          {t("mentionslegales.contenu.valeur")}
          <br /> <strong>{t("mentionslegales.contenu.valeur1")}</strong>
          FR36893374470 <br />{" "}
          <strong>{t("mentionslegales.contenu.valeur2")}</strong> 01 74 64 25 69{" "}
          <br /> <strong>{t("mentionslegales.contenu.valeur3")}</strong>
          contact@miel-montet.fr <br />
          {t("mentionslegales.contenu.valeur4")}
          <br />
          {t("mentionslegales.contenu.valeur5")}
          <br />
          <strong>{t("mentionslegales.contenu.valeur2")}</strong> 06 50 77 27 88{" "}
          <br />
          <strong>{t("mentionslegales.contenu.valeur3")}</strong>{" "}
          contact@new-pol.com <br />
          <br />
          <br /> <strong>{t("mentionslegales.presentation.titre1")}</strong>
          <br /> {t("mentionslegales.contenu.valeur8")}
          <br />
          {t("mentionslegales.contenu.valeur9")}
          <br />
          <strong>{t("mentionslegales.presentation.titre2")}</strong> <br />
          {t("mentionslegales.contenu.valeur10")} <br />
          {t("mentionslegales.contenu.valeur11")} <br />
          <strong>{t("mentionslegales.presentation.titre3")}</strong> <br />{" "}
          {t("mentionslegales.contenu.valeur12")} <br />
          {t("mentionslegales.contenu.valeur13")} 
          <br /> {t("mentionslegales.contenu.valeur14")}  <br />
          {t("mentionslegales.contenu.valeur15")} 
        </p>
        <br />
        <br />
      </div>

      <Footer />
    </>
  );
};

export default Mentionslegales;
