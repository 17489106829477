import React from "react";
import "./../assets/css/CGV.css";
import Footer from "../layouts/footer/footer";
import { Link } from "react-router-dom";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";
import { useTranslation } from "react-i18next";
import ProgressBar from "./ProgressBar ";

const CGV = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderUpdate />
      <ProgressBar />
      <br />
      <br />
      <br />
      <div className="container-fluid" id="cgv">
        <p>
          <strong>{t("cgv.ARTICLE_1")} </strong>
        </p>
        <p>{t("cgv.CONDITIONS_VENTE")}</p>
        <p>{t("cgv.CONDITIONS_VENTE_LANGUE")}</p>
        <p>{t("cgv.CONDITIONS_APPLICABLES")}</p>
        <p>{t("cgv.ACCEPTATION_CONDITIONS")}</p>
        <p>{t("cgv.CLIENT_DECLARATION")}</p>
        <p>{t("cgv.ADHESION_CONDITIONS")}</p>
        <p>{t("cgv.VERSION_IMPRIMEE")}</p>
        <p>{t("cgv.EXERCICE_PREROGATIVE")}</p>
        <p>{t("cgv.EVOLUTIONS_SITE")}</p>
        <p>
          <strong> {t("cgv.ARTICLE_2")}</strong>
        </p>
        <p>{t("cgv.CLIENT_CAPACITE")}</p>
        <p>
          <strong> {t("cgv.ARTICLE_3")}</strong>
        </p>
        <p>{t("cgv.CARACTERISTIQUES_PRODUITS")}</p>
        <p>
          <strong> {t("cgv.ARTICLE_4")}</strong>
        </p>
        <p>{t("cgv.COMMANDE_CATALOGUE")}</p>
        <p>{t("cgv.COMMANDE_SITE")}</p>
        <p>{t("cgv.OBLIGATION_PAIEMENT")}</p>
        <p>{t("cgv.ACCEPTATION_COMMANDE")}</p>
        <p>{t("cgv.PASSER_COMMANDE")}</p>
        <p>
          {" "}
          <ul>
            <li>{t("cgv.REMPLIR_PANIER")}</li>
            <li>{t("cgv.IDENTIFICATION")}</li>
            <li>{t("cgv.RENSEIGNER_ADRESSE")}</li>
            <li>{t("cgv.CHOISIR_LIVRAISON")}</li>
          </ul>
        </p>
        <p>{t("cgv.VERIFIER_COMMANDE")}</p>
        <p>
          {" "}
          <ul>
            <li>{t("cgv.CONFIRMATION_COMMANDE")}</li>
            <li>{t("cgv.IRREVOCABILITE_COMMANDE")}</li>
          </ul>
        </p>

        <p>{t("cgv.IMPRIMER_BON_COMMANDE")}</p>
        <p>
          <strong>{t("cgv.ARTICLE5")}</strong>
        </p>
        <p>
          {t("cgv.text1")}
          <br />
          {t("cgv.text2")}
          <br /> {t("cgv.text3")}
          <br /> {t("cgv.text4")}
        </p>
        <br />
        <p>
          <strong>
            {t("cgv.text5")} <br />
            {t("cgv.text6")}
          </strong>
        </p>
        <p>
          {t("cgv.text7")}
          <br /> {t("cgv.text8")}
          <br /> {t("cgv.text9")}
          <br /> {t("cgv.text10")}
          <br />
          {t("cgv.text11")}
        </p>
        <p>
          <strong>{t("cgv.text12")}</strong>
        </p>
        <p>{t("cgv.text13")}</p>
        <p>
          <strong>{t("cgv.text14")}</strong>
        </p>
        <p>
          {t("cgv.text15")}
          <br />
          {t("cgv.text16")}
          <br /> {t("cgv.text17")}
          <br /> {t("cgv.text18")}
          <br /> {t("cgv.text19")}
          <br /> {t("cgv.text20")}
        </p>
        <p>
          <strong>
            {t("cgv.text21")}
            <br /> {t("cgv.text22")}
          </strong>
        </p>
        <p>
          {t("cgv.text23")}
          <br /> {t("cgv.text24")}
          <br /> {t("cgv.text25")}
          <br /> {t("cgv.text26")} <br />
          {t("cgv.text27")}
        </p>
        <p>
          <strong>{t("cgv.text28")}</strong>
        </p>
        <p>
          {t("cgv.text29")}
          <br />
          {t("cgv.text30")}
          <br />
          {t("cgv.text31")}
          <br /> {t("cgv.text32")} <br />
          {t("cgv.text33")}
          <br /> {t("cgv.text34")}
          <br />
          {t("cgv.text35")}
        </p>
        <p>
          <strong>{t("cgv.text36")}</strong>
        </p>
        <p>
          {t("cgv.text37")} <br />
          {t("cgv.text38")}
          <br /> {t("cgv.text39")} <br /> {t("cgv.text40")}
          <br />
          {t("cgv.text41")}
          <br /> {t("cgv.text42")} <br />
          {t("cgv.text43")}
          <br /> {t("cgv.text44")}
          <br />
          {t("cgv.text45")} <br />
          {t("cgv.text46")}
        </p>

        <p>
          <strong>{t("cgv.text47")}</strong>
        </p>
        <p>{t("cgv.text48")}</p>
        <p>
          <strong>
            {t("cgv.text49")} <br />
            <Link
              className="lien-cgv"
              to="https://www.lamyline.fr/Content/Document.aspx?params=H4sIAAAAAAAEAMtMSbH1cTUAAnMTC3MLY7Wy1KLizPw827DM9NS8klS1xLzkjPwiW09jUyMAbqS9ViwAAAA=WKE"
            >
              {t("cgv.text50")}
            </Link>
          </strong>
        </p>
        <p>{t("cgv.text51")}</p>
        <p>
          <strong>
            <Link
              className="lien-cgv"
              to="https://www.lamyline.fr/Content/Document.aspx?params=H4sIAAAAAAAEAMtMSbH1cTUAAnMTC3MLY7Wy1KLizPw827DM9NS8klS1xLzkjPwiW09jUyMAbqS9ViwAAAA=WKE"
            >
              {t("cgv.text52")}
            </Link>
          </strong>
        </p>
        <p>
          {t("cgv.text53")} <br />
          <strong>1º</strong> {t("cgv.text54")} <br /> {t("cgv.text55")}
          <br /> {t("cgv.text56")}
          <br />
          <strong>2º</strong> {t("cgv.text57")}
        </p>
        <p>
          <strong>
            <Link
              className="lien-cgv"
              to="https://www.lamyline.fr/Content/Document.aspx?params=H4sIAAAAAAAEAMtMSbH1cTUAAnMTC3MLY7Wy1KLizPw827DM9NS8klS1xLzkjPwiW09jUyMAbqS9ViwAAAA=WKE"
            >
              {t("cgv.text58")}{" "}
            </Link>
          </strong>
        </p>
        <p>
        {t("cgv.text59")}
        </p>
        <p>
          <strong>
            <Link
              className="lien-cgv"
              to="https://www.lamyline.fr/Content/Document.aspx?params=H4sIAAAAAAAEAMtMSbH1cTUAAnMTC3MLY7Wy1KLizPw827DM9NS8klS1xLzkjPwiW09jUyMAbqS9ViwAAAA=WKE"
            >
              {t("cgv.text60")}
            </Link>
          </strong>
        </p>
        <p>
        {t("cgv.text61")}<br />
        {t("cgv.text62")}
        </p>
        <p>
          <strong>
          {t("cgv.text63")}<br />
            <Link
              className="lien-cgv"
              to="https://www.lamyline.fr/Content/Document.aspx?params=H4sIAAAAAAAEAMtMSbH1cTUAAnMTC3MLY7Wy1KLizPw827DM9NS8klS1xLzkjPwiW09jUyMAbqS9ViwAAAA=WKE"
            >
              {t("cgv.text64")}
            </Link>
          </strong>
        </p>
        <p>
        {t("cgv.text65")}
        </p>
        <p>
          <strong>
            <Link
              className="lien-cgv"
              to="https://www.lamyline.fr/Content/Document.aspx?params=H4sIAAAAAAAEAMtMSbH1cTUAAnMTC3MLY7Wy1KLizPw827DM9NS8klS1xLzkjPwiW09jUyMAbqS9ViwAAAA=WKE"
            >
              {t("cgv.text66")}
            </Link>
          </strong>
        </p>
        <p>
        {t("cgv.text67")}
        </p>
        <br />
        <br />
        <p>
          <strong>
          {t("cgv.text68")} <br /> {t("cgv.text69")}
          </strong>
        </p>
        <p>
        {t("cgv.text70")} <br />
        {t("cgv.text71")}
          <br /> {t("cgv.text72")}
          <br /> <strong>
          {t("cgv.text73")}
          </strong>{" "}
          <br />
          {t("cgv.text74")} <br />
          <strong>{t("cgv.text75")}</strong> <br />
          {t("cgv.text76")}<br />{" "}
          <strong>
          {t("cgv.text77")}{" "}
          </strong>{" "}
          <br /> {t("cgv.text78")}
          <br />
          <strong>{t("cgv.text79")} </strong> <br />
          {t("cgv.text80")} <br />
          {t("cgv.text81")}
          <br />
          {t("cgv.text82")}
          <br />{t("cgv.text83")}
          <br />{t("cgv.text84")}
          <br /> {t("cgv.text85")}
          <br /> {t("cgv.text86")}
          <br />{t("cgv.text87")}
          <br /> {t("cgv.text88")}
          <br />{t("cgv.text89")}
        </p>
        <br />
        <br />
        <p>
          <strong>{t("cgv.text90")}</strong> <br /> {t("cgv.text91")}
          <br />{t("cgv.text92")}<br /> {t("cgv.text93")}<br /> {t("cgv.text94")}<br />{" "}
          {t("cgv.text95")} <br /> {t("cgv.text96")} <br />
          {t("cgv.text97")} <br />
          {t("cgv.text98")}(*) <br /> {t("cgv.text99")} <br /> <br /> <br />
          <strong>{t("cgv.text100")} </strong> <br /> {t("cgv.text101")}<br />
          {t("cgv.text102")} <br />
          {t("cgv.text103")}
          <br /> {t("cgv.text104")}
          <br /> {t("cgv.text105")}<br /> {t("cgv.text106")} <br />{" "}
          {t("cgv.text107")} <br /> <br />{" "}
          <strong> {t("cgv.text108")}</strong> <br /> {t("cgv.text109")} <br />{" "}
          <strong>{t("cgv.text110")}</strong>{" "}
          <br /> {t("cgv.text111")}{" "}
          <br />
          {t("cgv.text112")}<br /> {t("cgv.text112")} <br />{" "}
          <strong>
          {t("cgv.text114")}
          </strong>{" "}
          <br />
          {t("cgv.text115")}<br /> {t("cgv.text116")}<br />
          {t("cgv.text117")} <br /> {t("cgv.text118")}{" "}
          <br />
          mediateurducommerce@fevad.com <br /> https://www.mediateurfevad.fr{" "}
          <br />{t("cgv.text119")} http://ec.europa.eu/consumers/odr/. <br />
          {t("cgv.text120")}
        </p>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default CGV;
