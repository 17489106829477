import React from 'react';
import { useTranslation } from "react-i18next";


//import css files
import "./../../assets/css/Footer/rectangle.css";

const Rectangle = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer2">
      <div className="overlay">
        <div className="container container-rectangle">
          <div className="footer-content w-100" >
            <div className=" ps-0 pb-0 footer-text d-flex  justify-content-center align-items-center" >
              <h2 className="pb-0 mb-0">{t("Rectangle.h2-text")}</h2>
            </div>
            <div className="subscribe-form pe-4">
              <input type="email" className='input-email' placeholder={t("Rectangle.placeholder")} />
              <button className=' size-montserrat'>{t("Rectangle.Sinscrire")}</button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Rectangle;