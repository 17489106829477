import React from "react";
import "./../assets/css/404.css";

const PageNotFound = () => {
    const locale = localStorage.getItem("i18nextLng");
  return (
    <>
        <div className="container page-404">
            <img className="img-fluid " src={require("./../assets/images/404.jpg")} alt="404.jpg" /> 
            <a href="/" className="text-decoration-none text-center">
                {locale === "en" ? " BACK TO THE HOME PAGE" : "RETOUR À LA PAGE D'ACCUEIL"}
            </a>
        </div>
    </>
  );
};

export default PageNotFound;
