import React from "react";
import { useTranslation } from "react-i18next";
// import css file
import "./../assets/css/PageCookies.css";
// import thes components
import Footer from "./../layouts/footer/footer";
import HeaderUpdate from "../layouts/Header/HeaderUpdate";



const PageCookies = () => {
    const { t } = useTranslation();
    return (
        <>
            <HeaderUpdate />
            <section className="container-fluid container-fluid-cookies p-0 m-0">
                <div className="container-cookies">
                        <div className="col-md-12 w-100">
                            <div className="col-md-12">
                                <h1 className="text-center">{t("Cookies.title1")}</h1>
                            </div>

                            <div className="col-md-12  mt-5">
                                <p className="m-auto">{t("Cookies.translated-text1")} </p>
                                <p className="m-auto"><span>{t("Cookies.translated-span1")}</span>{t("Cookies.translated-text2")} </p>
                                <p className="m-auto"><span>{t("Cookies.translated-span2")}</span>{t("Cookies.translated-text3")} </p>
                                <p className="m-auto"><span>{t("Cookies.translated-span3")}</span>{t("Cookies.translated-text4")} </p>
                                <p className="m-auto"><span>{t("Cookies.translated-span4")}</span>{t("Cookies.translated-text5")} </p>
                                <p className="m-auto"><span>{t("Cookies.translated-span5")}</span>{t("Cookies.translated-text6")} </p>
                                <p className="m-auto">{t("Cookies.translated-text7")} </p>
                            </div>
                            <div className="col-md-12">
                                <h1 className="text-center">{t("Cookies.title2")}</h1>
                            </div>
                            <div className="col-md-12  mt-5">
                                <p className="m-auto">{t("Cookies.translated-text8")} </p>
                                <p className="m-auto">{t("Cookies.translated-text9")} </p>
                                <p className="m-auto">{t("Cookies.translated-text10")} </p>
                                <p className="m-auto">{t("Cookies.translated-text11")} </p>
                                <p className="m-auto">{t("Cookies.translated-text12")} </p>
                            </div>
                            <div className="col-md-12  mt-5">
                                <ol start={1}>
                                    <li>{t("Cookies.liste.title1")} </li>
                                    <p className="m-auto">{t("Cookies.liste.text1")} </p>
                                    <p className="m-auto">{t("Cookies.liste.text2")} </p>
                                    <p className="m-auto">{t("Cookies.liste.text3")} </p>
                                    <p className="m-auto">{t("Cookies.liste.text4")} </p>
                                </ol>
                                <ol start={2}>
                                    <li>{t("Cookies.liste.title2")} </li>
                                    <p className="m-auto">{t("Cookies.liste.text5")} </p>
                                    <p className="m-auto">{t("Cookies.liste.text6")} </p>
                                </ol>
                            </div>
                            <div className="col-md-12 d-flex justify-content-center align-content-center" >
                                <table className="table table-bordered table-striped text-center" style={{ width: "90%" }}>
                                    <thead>
                                        <tr>
                                            <th >{t("Cookies.table1.title1")} </th>
                                            <th >{t("Cookies.table1.title2")} </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="w-50">{t("Cookies.table1.text1")} </td>
                                            <td className="w-50">{t("Cookies.table1.text2")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table1.text3")} </td>
                                            <td>{t("Cookies.table1.text4")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table1.text5")} </td>
                                            <td>{t("Cookies.table1.text6")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table1.text7")} </td>
                                            <td>{t("Cookies.table1.text8")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table1.text9")} </td>
                                            <td>{t("Cookies.table1.text10")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table1.text11")} </td>
                                            <td>{t("Cookies.table1.text12")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table1.text13")} </td>
                                            <td>{t("Cookies.table1.text14")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table1.text15")} </td>
                                            <td>{t("Cookies.table1.text16")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table1.text17")} </td>
                                            <td>{t("Cookies.table1.text18")} </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12">
                            <p className="m-auto">{t("Cookies.table1.para-text1")}</p>
                            </div>
                            <div className="col-md-12">
                                <ol start={1}> <li>{t("Cookies.liste2.title1")}</li></ol>
                                <p className="m-auto">{t("Cookies.liste2.text1")}</p>
                                <p className="m-auto">{t("Cookies.liste2.text2")}</p>
                                <p className="m-auto">{t("Cookies.liste2.text3")}</p>
                                <p className="m-auto">{t("Cookies.liste2.text4")}</p>
                            </div>
                            <div className="col-md-12">
                            <ol start={2}> <li>{t("Cookies.liste2.title2")}</li></ol>
                            <p className="m-auto">{t("Cookies.liste2.text5")}</p>
                            <ul >
                                <li>{t("Cookies.liste2.text6")}</li>
                                <li>{t("Cookies.liste2.text7")}</li>
                            </ul>
                            <p className="m-auto">{t("Cookies.liste2.text8")}</p>
                            <ul >
                                <li>{t("Cookies.liste2.text9")}</li>
                                <li>{t("Cookies.liste2.text10")}</li>
                            </ul>
                            </div>
                            <dir className="col-md-12 ">
                                <ol start={3}> <li>{t("Cookies.liste2.title3")}</li></ol>
                                <p className="m-auto">{t("Cookies.liste2.text11")}</p>
                                <ul >
                                    <li>{t("Cookies.liste2.text12")}</li>
                                    <li>{t("Cookies.liste2.text13")}</li>
                                    <li>{t("Cookies.liste2.text14")}</li>
                                    <li>{t("Cookies.liste2.text15")}</li>
                                </ul>
                                <p className="m-auto">{t("Cookies.liste2.text16")}</p>
                                <ul >
                                <li>{t("Cookies.liste2.text17")}</li>
                                <li>{t("Cookies.liste2.text18")}</li>
                            </ul>
                            <p className="m-auto">{t("Cookies.liste2.text19")}</p>
                            <p className="m-auto">{t("Cookies.liste2.text20")}</p>
                            <p className="m-auto">{t("Cookies.liste2.text21")}<span>{t("Cookies.liste2.span21")}</span></p>
                            <p className="m-auto">{t("Cookies.liste2.text22")}</p>
                            <p className="m-auto">{t("Cookies.liste2.text23")}</p>
                            </dir>
                            <dir className="col-md-12 ">
                                <ol start={4}> <li>{t("Cookies.liste2.title4")}</li></ol>
                                <p className="m-auto">{t("Cookies.liste2.text24")}</p>
                            </dir>
                            <div className="col-md-12 d-flex justify-content-center align-content-center" >
                                <table className="table table-bordered table-striped text-center" style={{ width: "90%" }}>
                                    <thead>
                                        <tr>
                                            <th >{t("Cookies.table2.title1")} </th>
                                            <th >{t("Cookies.table2.title2")} </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{t("Cookies.table2.text1")} </td>
                                            <td>{t("Cookies.table2.text2")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table2.text3")} </td>
                                            <td>{t("Cookies.table2.text4")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table2.text5")} </td>
                                            <td>{t("Cookies.table2.text6")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table2.text7")} </td>
                                            <td>{t("Cookies.table2.text8")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table2.text9")} </td>
                                            <td>{t("Cookies.table2.text10")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table2.text11")} </td>
                                            <td>{t("Cookies.table2.text12")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table2.text13")} </td>
                                            <td>{t("Cookies.table2.text14")} </td>
                                        </tr>
                                        <tr>
                                            <td>{t("Cookies.table2.text15")} </td>
                                            <td>{t("Cookies.table2.text16")} </td>
                                        </tr>
                                    </tbody>
                                   

                                </table>
                            </div>
                            <div className="col-md-12">
                            <p className="m-auto">{t("Cookies.table2.para-text1")}</p>
                                    <p className="m-auto">{t("Cookies.table2.para-text2")}</p>
                            </div>
                            <dir className="col-md-12 ">
                                <ol start={5}> <li>{t("Cookies.liste2.title5")}</li></ol>
                                <p className="m-auto">{t("Cookies.liste2.text25")}</p>
                            </dir>
                            <dir className="col-md-12 ">
                                <ol start={6}> <li>{t("Cookies.liste2.title6")}</li></ol>
                                <p className="m-auto">{t("Cookies.liste2.text26")}</p>
                                <p className="m-auto">{t("Cookies.liste2.text27")}
                                <span>{t("Cookies.liste2.span28")}</span>
                                {t("Cookies.liste2.text28")}
                                </p>
                            </dir>
                        </div>
                    
                </div>
            </section>
            <Footer />
        </>
    );
};

export default PageCookies