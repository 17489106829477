import React, { useEffect, useState } from 'react';

const ProgressBar = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop;
      const calculatedScrollPercentage = (scrollTop / scrollHeight) * 100;
      setScrollPercentage(calculatedScrollPercentage);

      // Show the progress bar when user scrolls down
      setIsVisible(scrollTop > 0);
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleClick = () => {
    // Scroll to top when progress bar is clicked
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      id="progress"
      style={{
        display: isVisible ? 'grid' : 'none',
        background: `conic-gradient(rgba(207, 154, 82, 0.68) ${scrollPercentage}%, rgba(255, 255, 255, 0.68) ${scrollPercentage}%)`,
        borderRadius: '50%',
        bottom: '30px',
        boxShadow: 'var(--shadow-1)',
        cursor: 'pointer',
        height: '50px',
        placeItems: 'center',
        position: 'fixed',
        right: '30px',
        width: '50px',
        zIndex: 999999,
      }}
      onClick={handleClick}
    >
      <span id="progress-value"
        style={{
          backgroundColor: '#fff',
          borderRadius: '50%',
          color: '#001a2e',
          display: 'grid',
          fontSize: '35px',
          height: '91%',
          placeItems: 'center',
          width: '91%',
        }}
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          className="scroll-to-top-icon"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            color: '#cf9a52', // Updated color
            fontSize: '27px',
            opacity: '0.8',
          }}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
        </svg>
      </span>
    </div>
  );
};

export default ProgressBar;
